<template>
    <div>
        <div class="flex items-center gap-5 mb-4">
            <img :src="data.icon" alt="">
            <h2 class="fs-16 font-bold">{{ data.title }}</h2>
        </div>
        <p class="fs-16 font-light mb-6">{{ data.subtitle }}</p>
        <div class="grid grid-rows-3 grid-cols-2 gap-y-2 gap-x-12 pr-10">
            <NavLink
                :link="{
                    label: 'Workspace',
                    to: { name: 'workspace_dashboard' }
                }"
            />
            <NavLink
                :link="{
                    label: 'Role Management',
                    to: { 
                        name: 'workspace.members'
                     }
                }"
            />
            <NavLink
                :link="{
                    label: 'Style Control',
                    to: { 
                        name: 'workspace_settings',
                        params: {
                            id: project.workspace_id || workspaceId
                        },
                        query: {
                            option: 'style',
                            id: project.id
                        }
                     }
                }"
            />
            <NavLink
                :link="{
                    label: 'Definition System',
                    to: {
                        name: 'definition',
                        params: {
                            id: projectId
                        }
                    }
                }"
            />
            <NavLink
                :link="{
                    label: 'Project Dashboard',
                    to: {
                        name: 'projectDashboard',
                        params: {
                            workspaceId: project.workspace_id || workspaceId,
                            projectId: projectId
                        }
                    }
                }"
            />
        </div>
        <router-link class="text-primary-four mt-5 inline-block" to="#">
            Learn more
        </router-link>
    </div>
</template>

<script setup>
    import NavLink from './NavLink.vue';
    import store from '@/store';
    import { computed } from 'vue';
    defineProps({
        data: Object
    });

    const workspace = computed(() => store.getters['workspace/activeWorkspaceObj']);
    const workspaceId = computed(() => workspace ? workspace.value?.id: null);
    const project = computed(() => store.getters['project/project']);
    const projectId = computed(() => project ? project.value?.id: null);
</script>