var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"flex items-center gap-5 mb-4"},[_c('img',{attrs:{"src":_vm.data.icon,"alt":""}}),_c('h2',{staticClass:"fs-16 font-bold"},[_vm._v(_vm._s(_vm.data.title))])]),_c('p',{staticClass:"fs-16 font-light mb-6"},[_vm._v(_vm._s(_vm.data.subtitle))]),_c('div',{staticClass:"grid grid-rows-3 grid-cols-2 gap-y-2 gap-x-12 pr-10"},[_c(_setup.NavLink,{attrs:{"link":{
                label: 'Workspace',
                to: { name: 'workspace_dashboard' }
            }}}),_c(_setup.NavLink,{attrs:{"link":{
                label: 'Role Management',
                to: { 
                    name: 'workspace.members'
                 }
            }}}),_c(_setup.NavLink,{attrs:{"link":{
                label: 'Style Control',
                to: { 
                    name: 'workspace_settings',
                    params: {
                        id: _setup.project.workspace_id || _setup.workspaceId
                    },
                    query: {
                        option: 'style',
                        id: _setup.project.id
                    }
                 }
            }}}),_c(_setup.NavLink,{attrs:{"link":{
                label: 'Definition System',
                to: {
                    name: 'definition',
                    params: {
                        id: _setup.projectId
                    }
                }
            }}}),_c(_setup.NavLink,{attrs:{"link":{
                label: 'Project Dashboard',
                to: {
                    name: 'projectDashboard',
                    params: {
                        workspaceId: _setup.project.workspace_id || _setup.workspaceId,
                        projectId: _setup.projectId
                    }
                }
            }}})],1),_c('router-link',{staticClass:"text-primary-four mt-5 inline-block",attrs:{"to":"#"}},[_vm._v(" Learn more ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }