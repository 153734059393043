<template>
    <div>
        <div class="flex items-center gap-5 mb-4">
            <img :src="data.icon" alt="">
            <h2 class="fs-16 font-bold">{{ data.title }}</h2>
        </div>
        <p class="fs-16 font-light mb-6">{{ data.subtitle }}</p>
        <div class="grid grid-rows-3 grid-cols-2 gap-y-2 gap-x-12 pr-10">
            <NavLink
                :link="{
                    label: 'Content Framework',
                    to: {
                        name: 'identification.build',
                        params: {
                            id: project.id
                        }
                    }
                }"
            />
            <NavLink
                :link="{
                    label: 'Scenario Content',
                    to: _ScenarioLink
                }"
            />
            <NavLink
                :link="{
                    label: 'Roadmap',
                    to: _RoadmapsLink
                }"
            />
            <NavLink
                :link="{
                    label: 'Present & Communicate',
                    to: _CommunicatorLink
                }"
            />
            <NavLink
                :link="{
                    label: 'Recurring Assessments',
                    to: {
                        name: 'intel.assess',
                        params: {
                            id: project.id,
                        }
                    }
                }"
            />
        </div>
        <router-link class="text-primary-four mt-5 inline-block" to="#">
            Learn more
        </router-link>
    </div>
</template>

<script setup>
    import NavLink from './NavLink.vue';
    import store from '@/store';
    import { computed, ref, watchEffect } from 'vue';
    import { useProduction } from '@/components/shared/stickyMenu/useProduction.js'
    const { initDefaultSettings, generateLink, ScenarioLink, RoadmapsLink, CommunicatorLink } = useProduction()
    defineProps({
        data: Object
    });
    const _ScenarioLink = ref({name: ''})
    const _RoadmapsLink = ref({name: ''})
    const _CommunicatorLink = ref({name: ''})


    const workspace = computed(() => store.getters['workspace/activeWorkspaceObj']);
    const project = computed(() => store.getters['project/project']);

    watchEffect(async () =>
    {
        if(!project.value) return;
        let _project = project.value;
        let Ids = await initDefaultSettings(_project.id)
        _ScenarioLink.value     = ScenarioLink(_project.id, Ids)
        _RoadmapsLink.value     = RoadmapsLink(_project.id, Ids)
        _CommunicatorLink.value = CommunicatorLink(_project.id, Ids)
    })

</script>