<template>
  <div class="_main_wrapper relative flex overflow-y-hidden" style="height: 1080px">
    <div class="i-left-panel shadow-one" style="height: 1080px">
      <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo">
      <div class="ws-boxes flex mt-12">
        <div class="flex workspace-scroll" v-click-outside="hideContextMenu">
          <div v-for="(workspace, wsIndex) in workspaces"
              :key="wsIndex"
              class="ws-box bg-primary-four font-poppins fs-16 text-primary-two p-2 uppercase fw-bold cursor-pointer rounded-sm"
              :class="{'ml-5': wsIndex > 0, 'i-border-1 shadow-two border-primary-one': parseInt(selectedWs) === parseInt(workspace.id)}"
              @click="toggleWorkspace(workspace.id, wsIndex)"
              @contextmenu="rightClickHandler($event, workspace)">
            {{ workspace.name.substring(0, 2) }}
          </div>
        </div>
        <div class="ws-box bg-primary-one cursor-pointer rounded-sm flex items-center justify-content-center p-3"
             :class="{'ml-5': workspaces.length > 0}" @click="showWorkspaceCreateModal()">
          <img src="/images/icons/workspace/plus_icon.svg" alt="icon">
        </div>
      </div>

      <div class="grid gap-3 ws-info-box i-mt-34">
        <h2 class="font-poppins fs-14 fw-600 text-primary-one">Active: {{ activeWorkspaceObj ? activeWorkspaceObj.name : '' }}</h2>
        <router-link class="mt-2" :to="{ name: 'workspace_settings', params: { id: activeWorkspaceObj ? activeWorkspaceObj.id : '' }, query: { option: 'style' }}">
          <h2 class="font-poppins fs-14 fw-600 text-primary-one opacity-50">Style Control</h2>
        </router-link>
        <router-link class="mt-2" to="/" :event="''" title="Please go to a project to see a definition">
          <h2 class="font-poppins fs-14 fw-600 text-primary-one opacity-50">Definitions</h2>
        </router-link>
        <router-link class="mt-2" :to="{ name: 'workspace.members'}">
          <h2 class="font-poppins fs-14 fw-600 text-primary-one opacity-50">Role & Members</h2>
        </router-link>
      </div>

      <!-- contextmenu start -->
      <div class="absolute bg-primary-three shadow-two fs-14 text-primary-one font-poppins py-3 px-3 rounded"
          style="width: 80px; height: 80px"
          v-if="showContextMenu && canEdit(contextWorkspace.cans)"
          :style="{'left': contextPos.left + 'px', 'top': contextPos.top + 'px'}">
        <ul>
          <li class="fw-600 cursor-pointer" @click="editWorkspace()" :class="{'pointer-events-none': !canEdit(contextWorkspace.cans)}">Edit</li>
          <li class="fw-600 cursor-pointer mt-3" @click="deleteWorkspace()" :class="{'pointer-events-none': !canDelete(contextWorkspace.cans)}">Delete</li>
        </ul>
      </div>
      <!-- contextmenu end -->

      <button class="ws-folder-button focus:outline-none text-primary-one fs-14 font-poppins fw-600 bg-primary-three shadow-one w-full mt-5 rounded" @click="visibleNewFolder()">+ New Folder</button>
      <div class="ws-folder-box i-border-b-1 border-secondary-two overflow-y-auto">
        <div class="flex justify-between">
          <h2 class="fs-14 font-poppins cursor-pointer text-primary-one opacity-60 fw-600" :class="{'opacity-100': selected_folder === 'all'}" @click="toggleFolderSelection('all')">All</h2>
          <h2 class="fs-14 font-poppins opacity-60 fw-600 text-primary-one" :class="{'opacity-100': selected_folder === 'all'}">{{ workspaceInfos.length }}</h2>
        </div>
        <div class="flex justify-between pt-8">
          <h2 class="fs-14 font-poppins cursor-pointer text-primary-one opacity-60 fw-600" :class="{'opacity-100': selected_folder === 'repository'}" @click="toggleFolderSelection('repository')">Repository</h2>
          <h2 class="fs-14 font-poppins opacity-60 fw-600 text-primary-one" :class="{'opacity-100': selected_folder === 'repository'}">{{ getRepositoryInfo().length }}</h2>
        </div>
        <div class="flex justify-between pt-8">
          <h2 class="fs-14 font-poppins cursor-pointer text-primary-one opacity-60 fw-600" :class="{'opacity-100': selected_folder === 'drafts'}" @click="toggleFolderSelection('drafts')">Drafts</h2>
          <h2 class="fs-14 font-poppins opacity-60 fw-600 text-primary-one" :class="{'opacity-100': selected_folder === 'drafts'}">{{ getPublicationDependentInfo(0).length }}</h2>
        </div>
        <div class="flex justify-between pt-8">
          <h2 class="fs-14 font-poppins cursor-pointer text-primary-one opacity-60 fw-600" :class="{'opacity-100': selected_folder === 'published'}" @click="toggleFolderSelection('published')">Published</h2>
          <h2 class="fs-14 font-poppins opacity-60 fw-600 text-primary-one" :class="{'opacity-100': selected_folder === 'published'}">{{ getPublicationDependentInfo(1).length }}</h2>
        </div>
<!--        <div class="flex justify-between pt-8">-->
<!--          <h2 class="fs-14 font-poppins cursor-pointer text-primary-one opacity-60 fw-600" :class="{'opacity-100': selected_folder === 'deleted'}" @click="toggleFolderSelection('deleted')">Deleted</h2>-->
<!--          <h2 class="fs-14 font-poppins opacity-60 fw-600 text-primary-one" :class="{'opacity-100': selected_folder === 'deleted'}">{{ getDeletedInfo().length }}</h2>-->
<!--        </div>-->
        <div class="flex justify-between pt-8 extra-folder-item" v-for="(directory, index) in directories" :key="index">
          <div class="flex">
            <h2 class="fs-14 font-poppins cursor-pointer text-primary-one opacity-60 fw-600" :class="{'opacity-100': parseInt(selected_folder) === parseInt(directory.id)}" @click="toggleFolderSelection(directory.id)">{{ directory.name }}</h2>
            <img src="/images/icons/trash.svg" alt="" class="w-4 ml-3 d-none cursor-pointer" @click="deleteFolder(directory.id)">
          </div>
          <h2 class="fs-14 font-poppins opacity-60 fw-600 text-primary-one" :class="{'opacity-100': parseInt(selected_folder) === parseInt(directory.id)}">{{ getCustomDirectoryInfo(directory.id).length }}</h2>
        </div>
        <div class="flex justify-between pt-8 mb-2" v-if="visible_folder_name">
          <input type="text" class="fs-14 font-poppins cursor-pointer text-primary-one bg-transparent i-border-1 border-secondary-two px-1" v-model="folder_name" v-click-outside="saveFolder" v-on:keyup.enter="saveFolder">
          <h2 class="fs-14 font-poppins text-primary-one">0</h2>
        </div>
      </div>

      <div class="ws-share-box">
        <h2 class="fs-14 font-poppins text-secondary-one">Publish Project</h2>
        <h2 class="fs-14 font-poppins text-secondary-one mt-5">Share on Instrat Community</h2>
        <h2 class="fs-14 font-poppins text-secondary-one mt-5">Invite collaborators</h2>
        <div class="social-links mt-6 flex">
          <img src="/images/icons/workspace/facebook.svg" alt="facebook" class="cursor-pointer ws-social-icon">
          <img src="/images/icons/workspace/google.svg" alt="google" class="i-ml-25 cursor-pointer ws-social-icon">
          <img src="/images/icons/workspace/linked_in.svg" alt="linked_in" class="i-ml-25 cursor-pointer ws-social-icon">
          <img src="/images/icons/workspace/youtube.svg" alt="youtube" class="i-ml-25 cursor-pointer ws-social-icon">
        </div>
      </div>
    </div>


    <div class="i-right-panel" style="height: 1080px">
      <div class="ws-dashboard-heading-box flex flex-col i-pb-50">
        <WorkspaceMenu :hide-project="true" />
        <div class="_content_wrapper">
          <Navigation @createNew="toggleModalMode(true)" />
          <router-view @openCreateModal="openCreateModal"></router-view>
        </div>
      </div>
    </div>

    <Modal v-model="dCreateModal">
        <component
          :is="modalComponent"
          :templateData="templateData"
          @toggleModalMode="toggleModalMode"
          @switchComponent="value => modalComponent = value"
        ></component>
    </Modal>
    <Modal v-model="eModal">
        <EditWorkspace
            :contextWorkspace="contextWorkspace"
            @toggleEditModal="toggleEditModal"
            @close="eModal = false"
        />
    </Modal>
  </div>
</template>

<script setup>
import usePermission from '@/composable/usePermission.js'
const { canDelete, canCreate, canEdit } = usePermission()
</script>
<script>

import { mapGetters } from 'vuex';
import WorkspaceMenu from "@/components/InstMenu/WorkspaceMenu";
import Popup from "@/components/notification/Popup";
import EditWorkspace from "@/components/workspace/WorkspaceEditModal";
import Navigation from "@/components/workspace/Navigation";
import ProjectCreateModal from "@/components/workspace/project/ProjectCreateModal"
import Modal from '@/elements/atom/Modal';
import StartingNavigationModal from '@/components/workspace/project/startingNav/Modal'
export default {
    name: "Index",
    components: {
        EditWorkspace,
        Navigation,
        Popup,
        WorkspaceMenu,
        // carousel,
        Modal,
        StartingNavigationModal,
        ProjectCreateModal
    },
  created() {
    const self = this;
    document.title = "Workspace Dashboard";
    if(self.workspaces.length <= 0) {
      self.$store.dispatch("workspace/fetchWorkspaces", 1).then(response => {
        if(response && response.data) {
          self.selectedWs = self.workspaces[0].id;
          self.$store.dispatch("workspace/toggleWorkspace", 0);
          self.$store.dispatch("workspace/getDirectories", self.workspaces[0].id);
          self.$store.dispatch("workspace/getWorkspaceData", {
            type: 1,
            workspace_id: self.workspaces[0].id
          });
        }
      });
    }
  },
  data() {
    return {
      eModal: false,
      contextPos: {
        top: 0,
        left: 0
      },
      templateData: null,
      modalComponent: 'ProjectCreateModal',
      showContextMenu: false,
      contextWorkspace: null,
      notification: false,
      dCreateModal: false,
      dEditModal: false,
      selectedProject: null,
      folder_name: '',
      visible_folder_name: false,
      type_selected: 1,
      selectedWs: false,
      categories: [
        { active: false },
        { active: false },
        { active: false },
      ],
    }
  },
  computed: {
    ...mapGetters({
      workspaces: 'workspace/workspaces',
      activeWorkspaceObj: "workspace/activeWorkspaceObj",
      directories: "workspace/directories",
      workspaceInfos: "workspace/workspaceInfos",
      projectSelection: 'workspace/projectSelection',
      notifications: 'notification/notifications',
      selected_folder: 'workspace/selected_folder',
    })
  },

  watch: {
    selectedWs(val) {
      this.getWorkspaceInfo(val)
    }
  },

  methods: {
    openCreateModal(project) {
      this.templateData = project;
      this.toggleModalMode(true);
    },
    showWorkspaceCreateModal() {
      this.$store.dispatch("workspace/toggleWorkspaceModal", true);
    },
    toggleModalMode(value) {
      this.dCreateModal = value;
      if(!value) {
        this.modalComponent = 'ProjectCreateModal';
      }
      if(!this.dCreateModal) {
        this.templateData = null;
      }
    },
    visibleNewFolder() {
      this.visible_folder_name = true;
    },
    saveFolder() {
      const self = this;
      if(self.folder_name) {
        self.$store.dispatch("workspace/createDirectory", {
          workspace_id: self.activeWorkspaceObj.id,
          name: self.folder_name
        }).then(response => {
          if(response) {
            self.visible_folder_name = false;
            self.$store.dispatch("workspace/getDirectories", self.activeWorkspaceObj.id);
          } else {
            alert('Something went wrong!!!');
          }
        });
      }
    },
    getAllInfo() {
      const self = this;
      return self.workspaceInfos;
    },
    getRepositoryInfo() {
      const self = this;
      return self.workspaceInfos.filter(item => !item.deleted_at || false || false);
    },
    getPublicationDependentInfo(status) {
      const self = this;
      return self.workspaceInfos.filter(item => (!item.deleted_at || false || false) && parseInt(item.status) === parseInt(status));
    },
    getDeletedInfo() {
      const self = this;
      return self.workspaceInfos.filter(item => item.deleted_at);
    },
    getCustomDirectoryInfo(directoryId) {
      const self = this;
      return self.workspaceInfos.filter(item => (!item.deleted_at || false || false) && parseInt(item.directory_id) === parseInt(directoryId));
    },
    toggleFolderSelection(folder) {
      this.$store.state.workspace.selected_folder = folder;
    },
    toggleWorkspace(id, index) {
      const self = this;
      self.selectedWs = id;
      self.$store.dispatch("workspace/toggleWorkspace", index);
      self.$store.dispatch("workspace/getDirectories", id);
      self.$store.dispatch("workspace/getWorkspaceData", {
        type: self.type_selected,
        workspace_id: id
      });
    },
    deleteFolder(id) {
      const self = this;
      if(confirm("Do you want to delete this?")) {
        self.$store.dispatch("workspace/deleteDirectory", id).then(response => {
          if(response) {
            self.$store.dispatch("workspace/getDirectories", self.activeWorkspaceObj.id);
            self.$store.dispatch("workspace/getWorkspaceData", {
              type: self.type_selected,
              workspace_id: self.activeWorkspaceObj.id
            });
            this.toggleFolderSelection('all')
          } else {
            alert('Something went wrong!!!');
          }
        })
      }
    },
    rightClickHandler(e, workspace) {
      e.preventDefault();
      this.showContextMenu = true;
      this.contextPos.left = e.pageX || e.clientX;
      this.contextPos.top = e.pageY || e.clientY;
      this.contextWorkspace = workspace;
    },
    hideContextMenu() {
      this.showContextMenu = false;
      this.contextWorkspace = null;
    },
    editWorkspace() {
      this.eModal = true;
      this.showContextMenu = false;
    },
    deleteWorkspace() {
      this.$Progress.start();
      this.$store.dispatch("workspace/deleteWorkspace", {
        id: this.contextWorkspace.id
      }).then(response => {
        this.$Progress.finish();
      })
    },
    toggleEditModal() {
      this.eModal = false;
      this.contextWorkspace = null;
    },
    getWorkspaceInfo(workspaceId) {
      const self = this;
      // let workspaceId = self.$route.params.id;
      self.$store.dispatch("workspace/getWorkspaceSettingsInfo", workspaceId).then(response => {
        if(response) {
          if(self.$route.query.type === 'project') {
            self.categories[0].active = true;
          } else if(self.$route.query.type === 'blueprint') {
            self.categories[1].active = true;
          } else if(self.$route.query.type === 'collection') {
            self.categories[2].active = true;
          }

          if(self.$route.query.id) {
            self.activeData = self.$route.query.id;
          }
        }
      });
    },
  },
}
</script>

<style scoped>

.extra-folder-item:hover img {
  display: block;
}
</style>
