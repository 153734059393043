
export const production = {
    icon: '/images/icons/production.svg',
    title: 'Content Management & Production',
    subtitle: 'Create content, roadmaps and data driven visual  communications',
    links: [
        {label: 'Content Framework', url: '#'},
        {label: 'Scenario Content', url: '#'},
        {label: 'Roadmap', url: '#'},
        {label: 'Present & Communicate', url: '#'},
        {label: 'Manage Properties', url: '#'},
    ],
    learn: '#'
}

export const teamwork = {
    icon: '/images/icons/creative_light.svg',
    title: 'Teamwork and business modeling',
    subtitle: 'Collaborate, author and build business and models on the fly!',
    links: [
        {label: 'Brainstorm', url: '#'},
        {label: 'Mindmap', url: '#'},
        {label: 'Business canvas', url: '#'},
        {label: 'Concept Authoring', url: '#'},
        {label: 'Workzone Charts', url: '#'},
    ],
    learn: '#'
}

export const manage = {
    icon: '/images/icons/doughnut_chart.svg',
    title: 'Management and configuration',
    subtitle: 'Understand instrat and manage your configuration and collaborators.',
    links: [
        {label: 'Role Management', url: '#'},
        {label: 'Style Control', url: '#'},
        {label: 'Definition System', url: '#'},
        {label: 'Project Dashboard', url: '#'},
    ],
    learn: '#'
}
