var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"flex items-center gap-5 mb-4"},[_c('img',{attrs:{"src":_vm.data.icon,"alt":""}}),_c('h2',{staticClass:"fs-16 font-bold"},[_vm._v(_vm._s(_vm.data.title))])]),_c('p',{staticClass:"fs-16 font-light mb-6"},[_vm._v(_vm._s(_vm.data.subtitle))]),_c('div',{staticClass:"grid grid-rows-3 grid-cols-2 gap-y-2 gap-x-12 pr-10"},[_c(_setup.NavLink,{attrs:{"link":{
                label: 'Brainstorm',
                to: _setup._BrainstormLink
            }}}),_c(_setup.NavLink,{attrs:{"link":{
                label: 'Mindmap',
                to: _setup._MindmapLink
            }}}),_c(_setup.NavLink,{attrs:{"link":{
                label: 'Business canvas',
                to: _setup._CanvasLink
            }}}),_c(_setup.NavLink,{attrs:{"link":{
                label: 'Concept Authoring',
                to: _setup._ConceptsLink
            }}}),_c(_setup.NavLink,{attrs:{"link":{
                label: 'Workzone Charts',
                to: _setup._ChartsLink
            }}})],1),_c('router-link',{staticClass:"text-primary-four mt-5 inline-block",attrs:{"to":"#"}},[_vm._v(" Learn more ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }