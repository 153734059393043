<template>
    <div>
        <div class="flex items-center gap-5 mb-4">
            <img :src="data.icon" alt="">
            <h2 class="fs-16 font-bold">{{ data.title }}</h2>
        </div>
        <p class="fs-16 font-light mb-6">{{ data.subtitle }}</p>
        <div class="grid grid-rows-3 grid-cols-2 gap-y-2 gap-x-12 pr-10">
            <NavLink
                :link="{
                    label: 'Brainstorm',
                    to: _BrainstormLink
                }"
            />
            <NavLink
                :link="{
                    label: 'Mindmap',
                    to: _MindmapLink
                }"
            />
            <NavLink
                :link="{
                    label: 'Business canvas',
                    to: _CanvasLink
                }"
            />
            <NavLink
                :link="{
                    label: 'Concept Authoring',
                    to: _ConceptsLink
                }"
            />
            <NavLink
                :link="{
                    label: 'Workzone Charts',
                    to: _ChartsLink
                }"
            />
        </div>
        <router-link class="text-primary-four mt-5 inline-block" to="#">
            Learn more
        </router-link>
    </div>
</template>

<script setup>
    import NavLink from './NavLink.vue';
    import store from '@/store';
    import { computed, ref, watchEffect } from 'vue';
    import { useTeamwork } from '@/components/shared/stickyMenu/useTeamwork.js'
    defineProps({
        data: Object
    })
    const _BrainstormLink = ref({name: ''})
    const _MindmapLink    = ref({name: ''})
    const _CanvasLink     = ref({name: ''})
    const _ConceptsLink   = ref({name: ''})
    const _ChartsLink     = ref({name: ''})

    const { initDefaultSettings, BrainstormLink, getLink } = useTeamwork()
    const workspace = computed(() => store.getters['workspace/activeWorkspaceObj']);
    watchEffect(async () => 
    {
        let project = store.getters['project/project']
        let Ids = await initDefaultSettings(project.id)

        _BrainstormLink.value = BrainstormLink(project.id, Ids.brainstorm_id)
        _MindmapLink.value    = getLink(project.id, Ids.brainstorm_id, 'teamwork.mindmap')
        _CanvasLink.value     = getLink(project.id, Ids.brainstorm_id, 'teamwork.canvas')
        _ConceptsLink.value   = getLink(project.id, Ids.brainstorm_id, 'teamwork.concepts')
        _ChartsLink.value     = getLink(project.id, Ids.brainstorm_id, 'teamwork.diagrammatic')

        return project;
    })
</script>