<template>
  <div class="bg-primary-three" style="width: 681px">
    <div class="p-5">
      <div class="flex i-border-b-1 border-secondary-five">
        <h3 class="font-poppins text-primary-one opacity-50 fs-20 cursor-pointer"
            :class="{'ws-modal-item-selector i-border-b-1 border-primary-one opacity-100': type_selected === 1}"
            @click="toggleWorkspaceInfo(1)">Project</h3>
        <h3 class="font-poppins text-primary-one opacity-50 fs-20 ws-modal-mr cursor-pointer"
            :class="{'ws-modal-item-selector i-border-b-1 border-primary-one opacity-100': type_selected === 2}"
            @click="toggleWorkspaceInfo(2)">Template</h3>
      </div>

      <div class="mt-5">
        <div>
            <h4 class="font-poppins text-secondary-one fs-14">{{ type_selected === 1 ? 'Project' : 'Template' }} Title</h4>
            <input
                type="text"
                v-model="projectData.name"
                class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-secondary-five rounded"
                placeholder="Type here"
                v-click-outside="outsideInput"
            >
        </div>

        <div class="flex mt-5 gap-x-3">
            <div class="w-full">
                <h4 class="font-poppins text-secondary-one fs-14 font-normal">Start Date</h4>
                <Datepicker
                  @selected="setStartDate"
                  input-class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two px-4 h-10 i-border-1 border-solid border-secondary-five rounded w-full"
                  placeholder="dd-mm-yyyy"
                  :format="'dd-MM-yyyy'"
                />
            </div>
            <div class="w-full">
                <h4 class="font-poppins text-secondary-one fs-14 font-normal">End Date</h4>
                <Datepicker
                    @selected="setEndDate"
                    input-class="bg-transparent mt-2 font-poppins text-secondary-one fs-14 placeholder-secondary-two px-4 h-10 i-border-1 border-solid border-secondary-five rounded w-full"
                    placeholder="dd-mm-yyyy"
                    :format="'dd-MM-yyyy'"
                />
          </div>
        </div>

        <div class="mt-5" v-if="!projectData.template_id">
            <h4 class="font-poppins text-secondary-one fs-14 font-normal">Style</h4>
            <select
                class="fs-14 font-poppins text-secondary-one ws-select-dropdown bg-transparent mt-2 rounded i-border-1 border-solid border-secondary-five w-full px-4"
                @change="setProjectStyle()"
                v-model="projectData.style_id"
            >
                <option value="null" selected="true">Select style</option>
                <option v-for="(style, index) in styles" :key="index" :value="style.id" class="">{{ style.title }}</option>
            </select>
        </div>

        <div class="mt-5">
            <h4 class="font-poppins text-secondary-one fs-14">{{ type_selected === 2 ? 'Project' : 'Template' }}</h4>
            <select class="fs-14 font-poppins text-secondary-one ws-select-dropdown bg-transparent mt-2 rounded i-border-1 border-solid border-secondary-five w-full px-4"
                    v-model="projectData.template_id" @change="toggleTemplateSelection(projectData.template_id)">
              <option value="null" selected="true">Select template</option>
              <option v-for="(temp, index) in getTemplateOrProjects" :key="index" :value="temp.id" class="">{{ temp.name }}</option>
            </select>
        </div>
        <div class="mt-5 border-b border-secondary-four pb-3" :class="{'pointer-events-none opacity-50': projectData.template_id}">
            <h4 class="font-poppins text-secondary-one fs-14">Select the breakdown structure</h4>
            <div class="flex mt-2 gap-11">
                    <Checkbox
                        v-for="level in levels"
                        :key="level.id"
                        :isChecked="level.active"
                        @onChecked="selectLevel(level)"
                        :label="level.text"
                    />
            </div>
        </div>
        <div class="mt-5">
          <h4 class="font-poppins text-secondary-one fs-14">Choose the Modules you want to keep</h4>
          <div class="grid grid-rows-3 grid-cols-3 mt-2 gap-y-3 gap-x-2 grid-flow-col">
            <div class="flex items-center gap-2" v-for="(module, index) in modules"  :key="`modules-${index}`">
                <Checkbox
                    :isChecked="module.active"
                    @onChecked="toggleModuleSelection(module)"
                />
                <input
                    type="text"
                    v-model="module.title"
                    class="bg-transparent focus:border focus:border-secondary-five font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-secondary-three rounded"
                    :class="{'inactive-text': module.active }"
                    :placeholder="module.placeholder"
                    @input="toggleModuleSelection(module, true)"
                >
            </div>
          </div>
        </div>
        <div class="mt-5">
          <h4 class="font-poppins text-secondary-one fs-14">Folder</h4>
          <select class="fs-14 font-poppins text-secondary-one ws-select-dropdown bg-transparent mt-2 rounded i-border-1 border-solid border-secondary-five w-full px-4" v-model="projectData.directory_id">
            <option value="">Select folder</option>
            <option v-for="(directory, index) in directories" :key="index" :value="directory.id">{{ directory.name }}</option>
          </select>
        </div>
        <div class="mt-5">
          <h4 class="font-poppins text-secondary-one fs-14">Collaborator</h4>
          <div class="flex justify-between items-center mt-2 relative">
            <div class="modal-collaborator flex-3 pr-3 relative">
              <input type="text" class="bg-transparent font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-secondary-five rounded"
                     :placeholder="placeholder.responsible" @click="placeholder.responsible = '';" v-click-outside="outsideResponsible"
                     v-model="responsible.value">
              <div class="ws-modal-role-selector absolute bg-transparent">
                <select class="fs-14 font-poppins text-secondary-one ws-select-dropdown bg-transparent" v-model="responsible.role">
                  <option v-for="(role, index) in roles" :key="index" :value="role.value">{{ role.title }}</option>
                </select>
              </div>
            </div>
            <button class="font-poppins fs-14 fw-600 h-10 w-40 flex-1 text-primary-one rounded border-secondary-one bg-transparent i-border-1" @click="addNewResponsible()" :disabled="loading">Add</button>
          </div>
          <div class="ws-suggestion-box bg-primary-three rounded" v-if="getUsers.length > 0 && responsible.value">
            <ul>
              <li v-for="(user, index) in getUsers" :key="index" @click="chooseUser(user)" class="fs-14 font-poppins text-primary-one px-4 py-2 cursor-pointer hover:bg-primary-two">
                {{ getUserName(user) }}
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-collaborators">
          <span v-for="(responsible, index) in responsibles" :key="index" :title="getRole(responsible.role)"
                class="fs-14 text-primary-one font-poppins mr-4 px-4 mt-4 pt-1 rounded i-border-1 border-secondary-five">
              {{ responsible.value }}
            <span class="ml-2 fs-12 cursor-pointer" style="border: none" @click="removeResponsible(index)">&#10006;</span></span>
        </div>

        <div class="mt-5 flex gap-6 items-center">
            <label class=" flex gap-3 items-center cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                    <path d="M0 0h24v24H0V0z" fill="none"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zM8 13h2.55v3h2.9v-3H16l-4-4z"/>
                </svg>
                <span>File Upload</span>
                <input @input="(e)=>{onChange(e), fileUpload(file)}" hidden type="file" accept="image/x-png,image/jpeg" />
            </label>
            <img v-if="previewURL" class="w-10 h-8 object-cover" :src="previewURL" />
        </div>

        <div class="flex mt-5">
          <button class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one" @click="createProject()" :disabled="disableClick">Create</button>
          <button class="focus:outline-none fs-14 fw-600 font-poppins i-pl-35 i-pr-35 i-border-1 border-secondary-one ml-5 rounded text-primary-one h-10 w-40" @click="closeModal(() => previewURL = '')">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onMounted } from 'vue'
  import { useFile } from '@/composable/useFile.js'
  let { onChange, previewURL, file } = useFile()
  onMounted(()=>{
    previewURL.value = ''
  })
</script>

<script>
import {mapGetters} from "vuex";
import Datepicker from 'vuejs-datepicker';
import Checkbox from "./Checkbox.vue";
import axios from 'axios';
import store from "@/store";

const levels = [
    {id: 4, text: '4 Level', active: true},
    {id: 3, text: '3 Level', active: false},
    {id: 2, text: '2 Level', active: false},
    {id: 1, text: '1 Level', active: false}
];
export default {
  name: "ProjectCreateModal",
  props: ['dModal', 'templateData'],
  components: {
    Datepicker,
    Checkbox
  },
  data() {
    return {
      type_selected: 1,
      formData: new FormData(),
      roles: [
        // { value: 1, title: 'Admin' },
        { value: 2, title: 'Editor' },
        { value: 4, title: 'Viewer' }
      ],
      responsibles: [],
      disableClick: false,
      levels,
      responsible: {
        role: 2,
        value: '',
        user_id: '',
        email: '',
        choosed: false
      },
      categories: [
        { value: 'business', title: 'Business' },
        { value: 'technology', title: 'Technology' },
        { value: 'software', title: 'Software' },
        { value: 'architecture', title: 'Architecture' },
      ],
      placeholder: {
        name: 'Your Project Name',
        responsible: 'Type email here'
      },
      projectData: {
        name: '',
        template_id: null,
        directory_id: '',
        start_date: '',
        end_date: '',
        style_id: null
      },
      modules: [
        { number: 1, title: 'Initiative  1', placeholder: ' module/dataset 1', active: true },
        { number: 2, title: '', placeholder: ' module/dataset 2', active: false },
        { number: 3, title: '', placeholder: ' module/dataset 3', active: false },
        { number: 4, title: '', placeholder: ' module/dataset 4', active: false },
        { number: 5, title: '', placeholder: ' module/dataset 5', active: false },
        { number: 6, title: '', placeholder: ' module/dataset 6', active: false },
        { number: 7, title: '', placeholder: ' module/dataset 7', active: false },
        { number: 8, title: '', placeholder: ' module/dataset 8', active: false },
        { number: 9, title: '', placeholder: ' module/dataset 9', active: false }
      ],
      styles: []
    }
  },
  computed: {
    ...mapGetters({
      directories: "workspace/directories",
      activeWorkspaceObj: "workspace/activeWorkspaceObj",
      workspaceUsers: "workspace/workspaceUsers",
      templates: "workspace/template_list",
      loading: "GET_LOADING_STATE"
    }),
    getUsers() {
      if (this.responsible.value && !this.responsible.choosed) {
        let user = JSON.parse(localStorage.getItem('_user'));
        return this.workspaceUsers.filter((item) => {
          return this.responsible.value
              .toLowerCase()
              .split(" ")
              .every((v) => {
                if(item.w_user_email && item.w_user_id !== user.id) {
                  return item.w_user_email.toLowerCase().includes(v)
                } else if(item.w_r_email && item.w_user_id !== user.id) {
                  return item.w_r_email.toLowerCase().includes(v)
                }
              });
        });
      } else {
        return [];
      }
    },
    getTemplateOrProjects() {
      let type = this.type_selected == 1 ? 2 : 1;
      return this.templates.filter(item => item.type === type);
    }
  },
  methods: {
    setProjectStyle() {
      if(!this.projectData.style_id || this.projectData.style_id == null || this.projectData.style_id == 'null') {
        this.projectData.style_id = null;
      }
    },
    openModal() {
      this.$store.dispatch("workspace/getWorkspaceUser", this.activeWorkspaceObj.id);
      this.$modal.show('project-create-modal');
      if(this.templateData && this.templateData.id) {
        this.projectData.template_id = this.templateData.id;
      }
    },
    selectLevel(level){
        this.levels.map(item => item.active = false);
        level.active = true;
    },
    closeModal(callback = () => {}) {
      this.responsible = {
        value: '',
        role: 2,
        user_id: '',
        email: '',
        choosed: false
      };
      this.projectData = {
        name: '',
        category: 'business',
        directory_id: '',
        type: '',
        workspace_id: '',
        modules: []
      }
      this.$emit('toggleModalMode', false);
      callback()
    },
    toggleWorkspaceInfo(type) {
      this.type_selected = type;
    },
    outsideInput() {
      this.placeholder.name = 'Your Project Name';
    },
    outsideResponsible() {
      this.placeholder.responsible = 'Type email here';
    },
    getUserName(user) {
      let title = '';
      if(user.w_user_first_name) {
        title = user.w_user_first_name;
        if(user.w_user_last_name) {
          title = title + ' ' + user.w_user_last_name;
        }
      } else if(user.w_user_email) {
        title = user.w_user_email;
      } else {
        title = user.w_r_email;
      }
      return title;
    },
    chooseUser(user) {
      if(user.w_user_id) {
        this.responsible.user_id = user.w_user_id;
        this.responsible.w_role = user.w_r_role;
        this.responsible.email = user.w_user_email;
        this.responsible.value = this.getUserName(user);
      } else {
        this.responsible.value = user.w_r_email;
        this.responsible.email = user.w_r_email;
        this.responsible.w_role = user.w_r_role;
      }
      this.responsible.choosed = true;
    },
    addNewResponsible() {
      if(this.responsible.email || this.responsible.user_id) {
        if((this.responsible.w_role == 3 || this.responsible.w_role == 4) && this.responsible.w_role != this.responsible.role) {
          alert('You can not add a workspace viewer as project editor. Make the user as editor in workspace first');
          return;
        }
        this.responsibles.push(this.responsible);
        this.responsible = {
          value: '',
          user_id: '',
          email: '',
          role: this.responsible.role,
          choosed: false
        };
      } else {
        alert('Please select user from workspace!!!');
      }
    },
    getRole(role) {
      let roleItem = this.roles.find(item => parseInt(item.value) === parseInt(role));
      if(roleItem) {
        return roleItem.title;
      }
      return '';
    },
    removeResponsible(index) {
      this.responsibles.splice(index, 1)
    },

    appends(data) {
        for(let key in data){
            this.formData.append(key, data[key])
        }
        return this.formData;
    },
    fileUpload(file){
      this.projectData.image = file
    },

    async createProject() {
      const self = this;
      if(this.getProjectModules().length) {
        if(self.projectData.name) {
          self.$store.state.loading = true;
          self.projectData.responsibles = self.responsibles;
          self.projectData.type = self.type_selected;
          self.projectData.workspace_id = self.activeWorkspaceObj.id;
          self.projectData.modules = this.getProjectModules();
          self.projectData.level = this.getLevel();
          self.projectData.json_data = this.getDefaultJsonData();

          // this.appends(self.projectData);
          //
          // for (let i = 0; i < self.responsibles.length; i++) {
          //     this.formData.append('responsibles[]', JSON.stringify(self.responsibles[i]));
          // }
          //
          // for (let j = 0; j < this.getProjectModules().length; j++) {
          //     this.formData.append('modules[]', JSON.stringify(this.getProjectModules()[j]));
          // }
          //
          // for (var pair of this.formData.entries()) {
          //     console.log(`${pair[0]} => ${ typeof pair[1]}`);
          // }

          self.$Progress.start();
          self.disableClick = true;
          self.$store.dispatch('workspace/storeProjectData', self.projectData).then(async response => {
              self.disableClick = false;
              self.$Progress.finish();
              if(response && response.data && response.data.status && response.data.data && response.data.data.id) {
                if(self.projectData.image) {
                  let responseData = response.data.data;
                  let fileData = self.projectData;
                  fileData.id = responseData.id;
                  fileData = self.appends(fileData);
                  self.$store.dispatch('workspace/storeProjectFile', fileData).then(async response => {
                    let { data } =  await self.$store.dispatch("workspace/getWorkspaceData", {
                      type: self.type_selected,
                      workspace_id: self.type_selected == 1 ? self.activeWorkspaceObj.id : 0
                    });
                  });
                }

                // self.closeModal();
                self.$store.state.loading = false;
                self.responsible = {
                  value: '',
                  user_id: '',
                  email: '',
                  role: 2,
                  choosed: false
                };
                self.projectData = {
                  name: '',
                  category: 'business',
                  directory_id: '',
                  type: '',
                  workspace_id: '',
                  modules: []
                }
                self.responsibles = [];

                if(self.type_selected == 1) {
                  let { data } =  await self.$store.dispatch("workspace/getWorkspaceData", {
                    type: 1,
                    workspace_id: self.activeWorkspaceObj.id
                  });
                  let project = data.find(project => project.id === response.data.data.id);
                  self.$store.commit("project/setProject", project);
                  this.$emit('switchComponent', 'StartingNavigationModal')
                } else {
                  // this.$router.push({ name: 'workspace.defaultTemplates' });
                  let { data } = this.$store.dispatch("workspace/getWorkspaceData", {
                    type: 2,
                    workspace_id: 0
                  });
                  // let project = data.find(project => project.id === response.data.data.id);
                  let project = response.data.data;
                  self.$store.commit("project/setProject", project);
                  this.$emit('switchComponent', 'StartingNavigationModal')
                }
                // self.$router.push({ name: 'identification.build', params: {id: response.data.data.id} });
              } else {
                self.$store.state.loading = false;
                alert('Something went wrong!!!')
              }
            });
        } else {
          alert('You have to fill all the fields');
        }
      } else {
        alert('You have to choose at least one module');
      }
    },
    toggleModuleSelection(module, input = false) {
        if(input) {
            if(module.title.length > 0) {
                module.active = true;
                return;
            }else {
                module.active = false;
            }
        }else {
            module.active = !module.active;
            if(!module.active) {
                module.title = ''
            }
        }
    },
    toggleTemplateSelection(id) {
      let templ = this.templates.find(item => item.id == id);
      if(templ) {
        this.modules = [];
        templ.modules.forEach((item, index) => {
          this.modules.push({ id: item.id, number: index + 1, title: item.title, placeholder: `module/dataset ${index + 1}`, active: true });
        });
      } else {
        this.modules = [
          { number: 1, title: 'Initiative  1', placeholder: ' module/dataset 1', active: true },
          { number: 2, title: '', placeholder: ' module/dataset 2', active: false },
          { number: 3, title: '', placeholder: ' module/dataset 3', active: false },
          { number: 4, title: '', placeholder: ' module/dataset 4', active: false },
          { number: 5, title: '', placeholder: ' module/dataset 5', active: false },
          { number: 6, title: '', placeholder: ' module/dataset 6', active: false },
          { number: 7, title: '', placeholder: ' module/dataset 7', active: false },
          { number: 8, title: '', placeholder: ' module/dataset 8', active: false },
          { number: 9, title: '', placeholder: ' module/dataset 9', active: false }
        ]
      }
    },
    getProjectModules() {
        return this.modules.filter(item => (item.active && item.title.length > 0));
    },
    getLevel() {
        return this.levels.find(item => item.active)
    },
    setStartDate(value) {
      this.projectData.start_date = this.$lodash.format(value, 'yyyy-MM-dd');
    },
    setEndDate(value) {
      this.projectData.end_date = this.$lodash.format(value, 'yyyy-MM-dd');
    },
    getDefaultJsonData() {
      return {
        parameters: [{ property_id: null, id: null, definition_id: null }],
        data_layer: { root_type: 'scenario', root_id: null, first_level_id: null, second_level_id: null, third_level_id: null, not_in_list: [] },
        settings: { theme: 'light' },
        definition_style: [
          { range: '0-2', bg: '#AD2B81', definition: 'Very Low' },
          { range: '2-4', bg: '#ABA62B', definition: 'Low' },
          { range: '4-6', bg: '#8D8D8D', definition: 'Medium' },
          { range: '6-8', bg: '#2BAD7E', definition: 'High' },
          { range: '8-10', bg: '#2B80AD', definition: 'Extreme' }
        ]
      };
    }
  },

  async created() {
    if(this.activeWorkspaceObj) {
        let { data } = await axios.get(`workspaces/${this.activeWorkspaceObj.id}/styles`).then(data => data.data);
        this.styles = data;
        this.$store.dispatch("workspace/getTemplates", { workspace_id: this.activeWorkspaceObj.id });
    }
    this.openModal()
  }
}
</script>
